.buttonShadow {
  box-shadow:
    16.3869px 14.6789px 31px rgb(0 0 0 / 31%),
    6.31198px 5.6541px 9.87407px rgb(0 0 0 / 18.8%),
    1.33523px 1.19606px 2.52593px rgb(0 0 0 / 12.2%);
}

.pricingCardBlur {
  /* backdrop-filter: blur(50px); */
}

.pricingCardWideGradient {
  background: linear-gradient(357.01deg, rgb(0 0 0 / 50%) 1.77%, rgb(0 0 0 / 50%) 42.27%, rgb(37 36 56 / 50%) 99.72%);
}

.statSuffixRegularGradient {
  background: var(--stat-suffix-gradient, linear-gradient(160.34deg, #fcfcfc 16.47%, rgb(252 252 252 / 0%) 83.51%));
  background-clip: text;
  mix-blend-mode: normal;
  -webkit-text-fill-color: transparent;
}

.inputField {
  /* backdrop-filter: blur(20px); */
  background: var(--color-alpha-dark-50);
  transition: background-color var(--duration-500ms) var(--ease-basic-butter);

  &:has(input:hover),
  &:has(input:focus) {
    background: transparent;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.labelEm {
  display: block;
  color: var(--color-alpha-light-50);

  @media (--md) {
    display: inline;
  }

  &::before {
    margin: 0 calc(1rem - 0.2em);
    color: var(--main-font-color);
    @media (--md) {
      content: '—';
    }
  }
}

.indentTitle2Quote {
  text-indent: -1.91rem;
}

.indentTitle4Quote {
  text-indent: -1rem;
}

.overlay {
  position: absolute;
  display: block;
  background-color: var(--color-grey-00);
  content: '';
  inset: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.1s;
}

.hasSpacerBefore {
  --spacer-height-before: var(--sp-h-b-sm);

  /* we overlap the sections for parallax and layering, so we need to add the value to the spacers too */
  /* stylelint-disable-next-line declaration-no-important */
  padding-top: calc(var(--spacer-height-before) + max(0px, var(--section-overlap-top, 0px))) !important;

  &.isFirstPageModule {
    /* if this is the first module of the page, we want to add the nav's height to it's padding top, so spacer values are correct */
    /* stylelint-disable-next-line declaration-no-important */
    padding-top: calc(var(--spacer-height-before) + var(--nav-height) + max(0px, var(--section-overlap-top, 0px))) !important;
  }

  @media (--md) {
    --spacer-height-before: var(--sp-h-b-md);
  }

  @media (--lg) {
    --spacer-height-before: var(--sp-h-b-lg);
  }

  @media (--xl) {
    --spacer-height-before: var(--sp-h-b-xl);
  }
}

.hasSpacerAfter {
  --spacer-height-after: var(--sp-h-a-sm);

  /* we overlap the sections for parallax and layering, so we need to add the value to the spacers too */

  /* stylelint-disable-next-line declaration-no-important */
  padding-bottom: calc(var(--spacer-height-after) + max(0px, var(--section-overlap-bottom, 0px)) - var(--fab-extra-space, 0px)) !important;

  @media (--md) {
    --spacer-height-after: var(--sp-h-a-md);
  }

  @media (--lg) {
    --spacer-height-after: var(--sp-h-a-lg);
  }

  @media (--xl) {
    --spacer-height-after: var(--sp-h-a-xl);
  }
}

/* clamps long lines of text and truncates with ellipsis */
.clampAndTruncate {
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--num-lines, 2);
  text-overflow: ellipsis;
}
