.wrapper {
  position: relative;
  height: 6.4rem;
}

.thumbnailsList {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.thumbnailListItem {
  display: flex;
  min-width: calc(var(--grid-gutter-size) * 3 + var(--grid-computed-column-width) * 4);
  flex-direction: row;
  align-items: center;
  opacity: 0.5;
  text-overflow: ellipsis;
  transition: opacity var(--ease-default) var(--duration-400ms);

  --active-progress: 0;

  &:hover {
    --active-progress: 1;
    opacity: 1;
  }

  &.active {
    --active-progress: 1;
    opacity: 1;
  }
}

.itemThumbnailButton {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.itemThumbnailImage {
  overflow: hidden;
  width: 10.8rem;
  height: var(--spacer-64);
  flex-shrink: 0;
  border-radius: var(--spacer-8);

  /* scale image when active (hovered & selected) */
  transform: scale(calc(var(--active-progress) * 0.05 + 1));
  transition: transform var(--ease-default) var(--duration-400ms);
}

.itemDetails {
  overflow: hidden;
  flex: 1;
  margin-left: var(--spacer-16);
  text-align: left;

  /* translate div when active (hover & selected) */
  transform: translateX(calc(var(--active-progress) * 0.2rem));
  transition: transform var(--ease-default) var(--duration-400ms);
}

.itemDetailsTitle {
  composes: label from '~typo';
  color: var(--color-grey-90);
}

.itemDetailsDescription {
  composes: label from '~typo';
  color: var(--color-alpha-light-50);
}

.itemDetailsSubtitle {
  overflow: hidden;
  margin-right: var(--spacer-16);
  text-overflow: ellipsis;
  white-space: nowrap;
}
